<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M23.25,7.75c0,1.7-2.537,5.678-3.437,7.036a.375.375,0,0,1-.625,0c-.9-1.357-3.438-5.332-3.438-7.036a3.75,3.75,0,0,1,7.5,0Z"
        />
        <path class="a" d="M19.5,7.375a.375.375,0,0,1,.375.375" />
        <path class="a" d="M19.125,7.75a.375.375,0,0,1,.375-.375" />
        <path class="a" d="M19.5,8.125a.375.375,0,0,1-.375-.375" />
        <path class="a" d="M19.875,7.75a.375.375,0,0,1-.375.375" />
        <path class="a" d="M9,4.375a.375.375,0,0,1,.375.375" />
        <path class="a" d="M8.625,4.75A.375.375,0,0,1,9,4.375" />
        <path class="a" d="M9,5.125a.375.375,0,0,1-.375-.375" />
        <path class="a" d="M9.375,4.75A.375.375,0,0,1,9,5.125" />
        <line class="a" x1="6.75" y1="22.75" x2="22.5" y2="17.5" />
        <line class="a" x1="13.555" y1="12.913" x2="15.742" y2="14.034" />
        <path class="a" d="M5.25,12.625A.375.375,0,0,1,5.625,13" />
        <path class="a" d="M4.875,13a.375.375,0,0,1,.375-.375" />
        <path class="a" d="M5.25,13.375A.375.375,0,0,1,4.875,13" />
        <path class="a" d="M5.625,13a.375.375,0,0,1-.375.375" />
        <path
            class="a"
            d="M5.25,8.5A4.5,4.5,0,0,1,9.75,13c0,1.921-2.688,5.577-3.909,7.138a.75.75,0,0,1-1.182,0C3.438,18.576.75,14.921.75,13A4.5,4.5,0,0,1,5.25,8.5Z"
        />
        <path
            class="a"
            d="M5.365,5.5a3.014,3.014,0,0,1-.115-.75,3.75,3.75,0,0,1,7.5,0,10.388,10.388,0,0,1-1.5,3.864"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TripMultipleDestinationsIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
