<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            d="M 1.614 6.2 L 2.286 6.2 C 2.515 6.2 2.7 6.385 2.7 6.614 L 2.7 8.3 L 4.393 8.3 C 4.389 8.201 4.387 8.101 4.387 8.001 C 4.387 3.803 7.79 0.4 11.988 0.4 C 16.185 0.4 19.587 3.803 19.587 8.001 C 19.587 8.101 19.585 8.201 19.581 8.3 L 21.3 8.3 L 21.3 6.634 C 21.3 6.406 21.485 6.22 21.714 6.22 L 22.386 6.22 C 22.615 6.22 22.8 6.406 22.8 6.634 L 22.8 23.184 C 22.8 23.412 22.615 23.598 22.386 23.598 L 21.714 23.598 C 21.485 23.598 21.3 23.412 21.3 23.184 L 21.3 21.5 L 16.2 21.5 L 16.2 23.157 C 16.2 23.386 16.032 23.571 15.825 23.571 L 15.075 23.571 C 14.868 23.571 14.7 23.386 14.7 23.157 L 14.7 21.5 L 9.3 21.5 L 9.3 23.186 C 9.3 23.415 9.132 23.6 8.925 23.6 L 8.175 23.6 C 7.968 23.6 7.8 23.415 7.8 23.186 L 7.8 21.5 L 2.7 21.5 L 2.7 23.166 C 2.7 23.394 2.515 23.579 2.286 23.579 L 1.614 23.579 C 1.385 23.579 1.2 23.394 1.2 23.166 L 1.2 6.614 C 1.2 6.385 1.385 6.2 1.614 6.2 Z M 2.7 9.8 L 2.7 14.2 L 7.59 14.2 C 6.121 13.156 5.039 11.604 4.601 9.8 L 2.7 9.8 Z M 21.3 14.2 L 21.3 9.8 L 19.373 9.8 C 18.935 11.604 17.853 13.156 16.385 14.2 L 21.3 14.2 Z M 16.2 15.7 L 16.2 20 L 21.3 20 L 21.3 15.7 L 16.2 15.7 Z M 14.7 15.7 L 9.3 15.7 L 9.3 20 L 14.7 20 L 14.7 15.7 Z M 7.8 15.7 L 2.7 15.7 L 2.7 20 L 7.8 20 L 7.8 15.7 Z M 14.074 4.244 L 11.52 4.244 C 11.356 4.245 11.21 4.35 11.158 4.507 L 9.885 8.334 C 9.846 8.45 9.865 8.577 9.937 8.678 C 10.009 8.777 10.124 8.837 10.247 8.837 L 11.274 8.837 C 11.394 8.837 11.506 8.892 11.578 8.987 C 11.65 9.081 11.675 9.205 11.643 9.32 L 10.889 12.087 C 10.87 12.163 10.907 12.243 10.979 12.276 C 11.051 12.309 11.136 12.286 11.179 12.22 L 14.368 7.901 C 14.447 7.783 14.453 7.632 14.387 7.507 C 14.321 7.383 14.191 7.306 14.05 7.306 L 13.079 7.306 C 13.034 7.305 12.992 7.28 12.97 7.242 C 12.948 7.202 12.948 7.153 12.973 7.115 L 14.398 4.83 C 14.472 4.712 14.476 4.563 14.41 4.442 C 14.342 4.32 14.213 4.244 14.074 4.244 Z"
            stroke="none"
            fill="currentColor"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'FenceGuardIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>
